<template>
	<v-row no-gutters>
		<v-dialog v-model="dialog.status" scrollable>
			<v-card v-if="dialog.status">
				<v-card-title class="font-weight-bold">
					<span v-if="dialog.status">{{dialog.dado.vendedor}}</span>
					<v-spacer />
					<v-btn color="primary" small fab text @click="dialog.status = false">
						<v-icon size="35">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pa-4">
					<v-skeleton-loader light v-if="carregandoDialog" type="table-row@3" />
					<v-simple-table dense v-else>
						<thead>
							<tr>
								<th>Campanha</th>
								<th>Venda Bruta</th>
								<th>Devolução</th>
								<th>Venda Líquida</th>
								<th>Margem</th>
								<th>Comissão</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(u,i) in dialog.dados" :key="i">
								<td>
									{{u.campanha}}
									<span v-if="u.observacao != ''">- {{u.observacao}}</span>
								</td>
								<td>{{u.venda | formataDinheiro}}</td>
								<td>{{u.devolucao | formataDinheiro}}</td>
								<td>{{u.valorliquido | formataDinheiro}}</td>
								<td class="text-center" v-if="u.margem">{{u.margem.toFixed(2)}} %</td>
								<td class="text-center" v-else>0.00 %</td>
								<td>{{u.comissao | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tfoot v-if="dialog.dados.length > 0">
							<tr class="font-weight-bold">
								<td>Total</td>
								<td
									class="text-truncate"
								>{{dialog.dados.map(v => v.venda).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td
									class="text-truncate"
								>{{dialog.dados.map(v => v.devolucao).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td
									class="text-truncate"
								>{{dialog.dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td
									class="text-truncate text-center"
								>{{(dialog.dados.map(v => v.lucro).reduce((a,v) => a+v) / dialog.dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
								<td
									class="text-truncate"
								>{{dialog.dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" block @click="dialog.status = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" class="mb-n7">
			<v-card class="title">
				<v-card-title>
					<v-row no-gutters>
						<v-col class="d-none d-md-block pr-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtinicio"
								label="Data inicial"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtfim"
								label="Data final"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-autocomplete
								:items="filiaisFiltro"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idfilial"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-autocomplete
								no-filter
								:items="itemsVendedor"
								clearable
								:search-input.sync="searchVendedor"
								item-text="nome"
								item-value="idcliente"
								label="Vendedor"
								outlined
								dense
								v-model="busca.idvendedor"
							></v-autocomplete>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-autocomplete
								:items="secoes.lista"
								clearable
								item-text="secao"
								item-value="idsecao"
								label="Seção"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idsecao"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-autocomplete
								:items="produtos.lista"
								clearable
								item-text="produto"
								item-value="idproduto"
								label="Produto"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idproduto"
							/>
						</v-col>
						<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
							<v-sheet class="text-center" height="100%">
								<v-row class="pa-3">
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtinicio"
											label="Data inicial"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtfim"
											label="Data final"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6" class="mb-n8 px-2">
										<v-autocomplete
											:items="filiaisFiltro"
											clearable
											item-text="filial"
											item-value="idfilial"
											label="Filial"
											outlined
											dense
											:disabled="carregando"
											v-model="busca.idfilial"
										/>
									</v-col>
									<v-col cols="6" class="mb-n8 px-2">
										<v-autocomplete
											no-filter
											:items="itemsVendedor"
											clearable
											:search-input.sync="searchVendedor"
											item-text="nome"
											item-value="idcliente"
											label="Vendedor"
											outlined
											dense
											v-model="busca.idvendedor"
										></v-autocomplete>
									</v-col>
									<v-col cols="6" class="mb-n8 px-2">
										<v-autocomplete
											:items="secoes.lista"
											clearable
											item-text="secao"
											item-value="idsecao"
											label="Seção"
											outlined
											dense
											:disabled="carregando"
											v-model="busca.idsecao"
										/>
									</v-col>
									<v-col cols="6" class="mb-n8 px-2">
										<v-autocomplete
											:items="produtos.lista"
											clearable
											item-text="produto"
											item-value="idproduto"
											label="Produto"
											outlined
											dense
											:disabled="carregando"
											v-model="busca.idproduto"
										/>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="listar(), sheet = !sheet">Filtrar</v-btn>
									</v-col>
								</v-row>
							</v-sheet>
						</v-bottom-sheet>
						<v-col class="pl-1">
							<v-text-field
								class="d-flex d-md-none"
								label="Nota/Cupom/Pedido"
								type="number"
								outlined
								dense
								:disabled="carregando"
								v-model.number="busca.idpedido"
								append-outer-icon="mdi-menu"
								@click:append-outer="sheet = !sheet"
							/>
							<v-text-field
								class="d-none d-md-block"
								label="Nota/Cupom/Pedido"
								type="number"
								outlined
								dense
								:disabled="carregando"
								v-model.number="busca.idpedido"
							/>
						</v-col>
						<v-col cols="auto" class="ml-2">
							<v-btn
								:disabled="carregando"
								class="mt-1"
								color="primary"
								elevation="0"
								fab
								x-small
								@click="listar()"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-tabs v-model="tab" grow background-color="primary">
				<v-tab class="white--text" :disabled="carregando">Filial</v-tab>
				<v-tab :disabled="carregando" class="white--text">Vendedor</v-tab>
				<v-tab :disabled="carregando" class="white--text">Pedido</v-tab>
				<v-tab :disabled="carregando" class="white--text">Seção</v-tab>
				<v-tab :disabled="carregando" class="white--text">Produto</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat dark color="primary" class="rounded-0">
						<v-card-text>
							<v-skeleton-loader light v-if="carregando" type="table-tbody" />
							<v-simple-table v-else fixed-header dense height="50vh" light>
								<thead>
									<tr>
										<th>Filial</th>
										<th>Venda</th>
										<th>Devolução</th>
										<th>Total</th>
										<th>Lucro</th>
										<th>Margem</th>
										<th>Comissão</th>
										<th>% Comissão</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(u,i) in dados" :key="i">
										<td class="text-truncate">{{u.idfilial}} - {{u.filial}}</td>
										<td>{{u.venda | formataDinheiro}}</td>
										<td>{{u.devolucao | formataDinheiro}}</td>
										<td>{{u.valorliquido | formataDinheiro}}</td>
										<td>{{u.lucro | formataDinheiro}}</td>
										<td>{{u.margem.toFixed(2)}} %</td>
										<td>{{u.comissao | formataDinheiro}}</td>
										<td>{{(u.comissao/u.valorliquido * 100).toFixed(2)}} %</td>
									</tr>
								</tbody>
								<tfoot v-if="dados.length > 0">
									<tr class="font-weight-bold">
										<td>Total</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.venda).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.devolucao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.lucro).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.lucro).reduce((a,v) => a+v) / dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.comissao).reduce((a,v) => a+v)/dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat dark color="primary" class="rounded-0">
						<v-card-text>
							<v-skeleton-loader light v-if="carregando" type="table-tbody" />
							<v-simple-table v-else fixed-header dense height="50vh" light>
								<thead>
									<tr>
										<th class="pr-1"></th>
										<th class="px-1">Filial</th>
										<th class="px-1">Vendedor</th>
										<th class="px-1">Venda</th>
										<th class="px-1">Devolução</th>
										<th class="px-1">Total</th>
										<th class="px-1">Lucro</th>
										<th class="px-1">Margem</th>
										<th class="px-1">Meta</th>
										<th class="px-1">Atingimento</th>
										<th class="px-1">Comissão</th>
										<th class="text-truncate px-1">% Comissão</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(u,i) in dados" :key="i" @dblclick="abrirDialog(u)">
										<td class="pr-1">
											<v-icon @click="abrirDialog(u)" class="primary--text">mdi-eye</v-icon>
										</td>
										<td class="text-truncate px-1">{{u.idfilial}} - {{u.filial}}</td>
										<td class="text-truncate px-1">{{u.idvendedor}} - {{u.vendedor | formataTextoCurto}}</td>
										<td class="px-1">{{u.venda | formataDinheiro}}</td>
										<td class="px-1">{{u.devolucao | formataDinheiro}}</td>
										<td class="px-1">{{u.valorliquido | formataDinheiro}}</td>
										<td class="px-1">{{u.lucro | formataDinheiro}}</td>
										<td class="text-truncate text-center px-1">{{u.margem.toFixed(2)}} %</td>
										<td class="px-1">{{u.meta | formataDinheiro}}</td>
										<td v-if="u.atingimento" class="text-center px-1">{{u.atingimento.toFixed(2)}} %</td>
										<td v-else class="text-center px-1">0.00 %</td>
										<td class="px-1">{{u.comissao | formataDinheiro}}</td>
										<td class="text-center px-1">{{(u.comissao/u.valorliquido * 100).toFixed(2)}} %</td>
									</tr>
								</tbody>
								<tfoot v-if="dados.length > 0">
									<tr class="font-weight-bold">
										<td class="pr-1" colspan="3">Total</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.venda).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.devolucao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.lucro).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate text-center px-1"
										>{{(dados.map(v => v.lucro).reduce((a,v) => a+v) / dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.meta).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											v-if="dados.map(v => v.meta).reduce((a,v) => a+v) > 0"
											class="text-truncate text-center px-1"
										>{{(dados.map(v => v.valorliquido).reduce((a,v) => a+v) / dados.map(v => v.meta).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td v-else class="text-truncate text-center px-1">0.00 %</td>
										<td
											class="text-truncate px-1"
										>{{dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate text-center px-1"
										>{{(dados.map(v => v.comissao).reduce((a,v) => a+v)/dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat dark color="primary" class="rounded-0">
						<v-card-text>
							<v-skeleton-loader light v-if="carregando" type="table-tbody" />
							<v-simple-table v-else fixed-header dense height="50vh" light>
								<thead>
									<tr>
										<th>Filial</th>
										<th>Pedido</th>
										<th>Cliente</th>
										<th>Venda Líquida</th>
										<th>Lucro</th>
										<th>Margem</th>
										<th>Comissão</th>
										<th>% Comissão</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(u,i) in dados" :key="i">
										<td class="text-truncate">{{u.idfilial}} - {{u.filial}}</td>
										<td>{{u.pedido}}</td>
										<td>{{u.idcliente}} - {{u.cliente | formataTextoCurto}}</td>
										<td>{{u.valorliquido | formataDinheiro}}</td>
										<td>{{u.lucro | formataDinheiro}}</td>
										<td>{{u.margem.toFixed(2)}} %</td>
										<td>{{u.comissao | formataDinheiro}}</td>
										<td>{{(u.comissao/u.valorliquido * 100).toFixed(2)}} %</td>
									</tr>
								</tbody>
								<tfoot v-if="dados.length > 0">
									<tr class="font-weight-bold">
										<td colspan="3">Total</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.lucro).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.lucro).reduce((a,v) => a+v) / dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.comissao).reduce((a,v) => a+v)/dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat dark color="primary" class="rounded-0">
						<v-card-text>
							<v-skeleton-loader light v-if="carregando" type="table-tbody" />
							<v-simple-table v-else fixed-header dense height="50vh" light>
								<thead>
									<tr>
										<th>Seção</th>
										<th>Venda</th>
										<th>Devolução</th>
										<th>Total</th>
										<th>Lucro</th>
										<th>Margem</th>
										<th>Comissão</th>
										<th>% Comissão</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(u,i) in dados" :key="i">
										<td class="text-truncate">{{u.idsecao}} - {{u.secao}}</td>
										<td>{{u.venda | formataDinheiro}}</td>
										<td>{{u.devolucao | formataDinheiro}}</td>
										<td>{{u.valorliquido | formataDinheiro}}</td>
										<td>{{u.lucro | formataDinheiro}}</td>
										<td>{{u.margem.toFixed(2)}} %</td>
										<td>{{u.comissao | formataDinheiro}}</td>
										<td>{{(u.comissao/u.valorliquido * 100).toFixed(2)}} %</td>
									</tr>
								</tbody>
								<tfoot v-if="dados.length > 0">
									<tr class="font-weight-bold">
										<td>Total</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.venda).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.devolucao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.lucro).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.lucro).reduce((a,v) => a+v) / dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.comissao).reduce((a,v) => a+v)/dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat dark color="primary" class="rounded-0">
						<v-card-text>
							<v-skeleton-loader light v-if="carregando" type="table-tbody" />
							<v-simple-table v-else fixed-header dense height="50vh" light>
								<thead>
									<tr>
										<th>Produto</th>
										<th>Seção</th>
										<th>Valor Líquido</th>
										<th>Lucro</th>
										<th>Margem</th>
										<th>Comissão</th>
										<th>% Comissão</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(u,i) in dados" :key="i">
										<td class="text-truncate">{{u.idproduto}} - {{u.produto | formataTextoCurto}}</td>
										<td class="text-truncate">{{u.idsecao}} - {{u.secao | formataTextoCurto}}</td>
										<td>{{u.valorliquido | formataDinheiro}}</td>
										<td>{{u.lucro | formataDinheiro}}</td>
										<td>{{u.margem.toFixed(2)}} %</td>
										<td>{{u.comissao | formataDinheiro}}</td>
										<td>{{(u.comissao/u.valorliquido * 100).toFixed(2)}} %</td>
									</tr>
								</tbody>
								<tfoot v-if="dados.length > 0">
									<tr class="font-weight-bold">
										<td colspan="2">Total</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.valorliquido).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.lucro).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.lucro).reduce((a,v) => a+v) / dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
										<td
											class="text-truncate"
										>{{dados.map(v => v.comissao).reduce((a,v) => a+v) | formataDinheiro}}</td>
										<td
											class="text-truncate"
										>{{(dados.map(v => v.comissao).reduce((a,v) => a+v)/dados.map(v => v.valorliquido).reduce((a,v) => a+v) * 100).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
export default {
	name: "ComissaoVendas",
	mixins: [mixinFilial],
	components: { InputDatePicker },
	data: () => ({
		tab: 0,
		dialog: {
			status: false,
			grafico: false,
			dado: { vendedor: "" },
			dados: [],
		},
		carregando: true,
		carregandoDialog: false,
		sheet: false,
		dados: [],
		secoes: {},
		produtos: {},
		busca: {
			visao: "Filial",
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idstatus: null,
			idsituacao: null,
		},
		aguardarBusca: "",
		nomeLimit: 50,
		vendedores: [],
		searchVendedor: null,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista
					.map((v) => {
						return {
							idfilial: v.idfilial,
							filial: `${v.idfilial} - ${v.filial}`,
						};
					})
					.filter((v) => {
						return (
							v.idfilial != 6 &&
							v.idfilial != 12 &&
							v.idfilial != 25 &&
							v.idfilial != 29 &&
							v.idfilial != 33 &&
							v.idfilial != 37
						);
					});
			} else {
				return [];
			}
		},
		itemsVendedor() {
			if (this.vendedores == null) return;

			return this.vendedores.map((v) => {
				return {
					idcliente: v.idcliente,
					nome: `${v.idcliente} - ${v.nome}`,
				};
			});
		},
	},
	methods: {
		defineVisao() {
			switch (this.tab) {
				case 0:
					this.busca.visao = "Filial";
					break;
				case 1:
					this.busca.visao = "Vendedor";
					break;
				case 2:
					this.busca.visao = "Pedido";
					break;
				case 3:
					this.busca.visao = "Secao";
					break;
				case 4:
					this.busca.visao = "Produto";
					break;
				default:
					this.busca.visao = "Filial";
					this.tab = 0;
					break;
			}
		},
		listar() {
			this.carregando = true;
			this.defineVisao();
			return axios
				.post(`${this.backendUrl}comissao/listar`, {
					visao: this.busca.visao,
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfilial,
					idvendedor: this.busca.idvendedor,
					idsecao: this.busca.idsecao,
					idproduto: this.busca.idproduto,
					idpedido: parseInt(this.busca.idpedido),
				})
				.then((res) => {
					if (res.data) {
						this.dados = res.data;
					} else {
						this.dados = [];
					}
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarSecoes() {
			this.secoes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/secao/listar`, {
					idfabricante: null,
				})
				.then((res) => {
					this.secoes = res.data;
					this.secoes.lista = this.secoes.lista.map((v) => {
						return {
							idsecao: v.idsecao,
							secao: `${v.idsecao} - ${v.secao}`,
						};
					});
				});
		},
		listarProdutos() {
			this.produtos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/filtro/listar`, {})
				.then((res) => {
					this.produtos = res.data;
					this.produtos.lista = this.produtos.lista.map((v) => {
						return {
							idproduto: v.idproduto,
							produto: `${v.idproduto} - ${v.produto}`,
						};
					});
				});
		},
		carregarVendedores(val) {
			clearTimeout(this.aguardarBusca);

			this.aguardarBusca = setTimeout(() => {
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						tipo: "'U','R','V'",
						busca: val || null,
					})
					.then((res) => {
						this.vendedores = res.data.lista;
					});
			}, this.timeLimit);
		},
		abrirDialog(dado) {
			let dados = JSON.parse(JSON.stringify(dado));
			this.dialog.dado = dados;
			this.dialog.status = true;
			this.carregandoDialog = true;
			return axios
				.post(`${this.backendUrl}comissao/campanha/listar`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idvendedor: dados.idvendedor,
				})
				.then((res) => {
					if (res.data) {
						this.dialog.dados = res.data;
					} else {
						this.dialog.dados = [];
					}
					this.carregandoDialog = false;
				})
				.catch(() => {
					this.carregandoDialog = false;
				});
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			await this.listarSecoes();
			await this.listarProdutos();
			await this.listar();
		},
	},
	watch: {
		searchVendedor: function (val) {
			this.carregarVendedores(val);
		},
		tab: function () {
			this.listar();
		},
	},
	created() {
		this.init();
	},
};
</script>